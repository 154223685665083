// cleanupLinks.ts

/**
 * Recursively traverse a JavaScript value, removing `item` inside `link`.
 * This will handle nested arrays and objects of arbitrary depth.
 *
 * @param value - The current value to process (can be an object, array, or primitive).
 */
function removeItemInLink(value: unknown): void {
    if (Array.isArray(value)) {
        // For arrays, traverse each element
        for (let i = 0; i < value.length; i++) {
            removeItemInLink(value[i])
        }
    } else if (value && typeof value === 'object') {
        // This cast allows us to safely access properties as an object
        const obj = value as Record<string, unknown>

        // If this object has a "link" sub-object with an "item" field, remove it
        if (obj.link && typeof obj.link === 'object') {
            const linkObj = obj.link as Record<string, unknown>
            if ('item' in linkObj) {
                delete linkObj.item
            }
        }

        // Recursively traverse all properties of the object
        for (const key of Object.keys(obj)) {
            removeItemInLink(obj[key])
        }
    }
}

/**
 * The shape of your entire data object.
 * Adjust this interface based on your actual structure
 * if you want stronger type-checking.
 */
export interface DataObject {
    websiteID: string
    content: {
        content: string // This should be a JSON string that we'll parse
        [key: string]: unknown
    }
    media: unknown
    type: string
    [key: string]: unknown // Allow other possible fields
}

/**
 * Cleans up the JSON in `content.content` by removing `item` from any `link`.
 *
 * @param data - The DataObject containing the JSON string inside `data.content.content`.
 * @returns The same DataObject with `content.content` modified in-place.
 */
export function cleanupLinkItems(data: DataObject): DataObject {
    if (!data?.content?.content) {
        return data
    }

    // 1. Parse the JSON string in data.content.content
    let parsed: unknown
    try {
        parsed = JSON.parse(data.content.content)
    } catch (err) {
        // If it fails to parse, just return data as-is or handle the error
        console.error('Error parsing data.content.content:', err)
        return data
    }

    // 2. Perform the deep cleanup
    removeItemInLink(parsed)

    // 3. Convert back to JSON and store it in data.content.content
    data.content.content = JSON.stringify(parsed, null, 2)

    // 4. Return the modified data
    return data
}
