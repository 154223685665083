import AssetGrid from 'components/AssetGrid/AssetGrid'
import CMSHeader from 'components/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerGridProps, DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import AssetDetailsPanel from 'drawerPanels/AssetDetailsPanel'
import AssetFolderPanel from 'drawerPanels/AssetFolderPanel'
import AssetsFolderEditPanel from 'drawerPanels/AssetsFolderCreatePanel'
import AssetsUploadPanel from 'drawerPanels/AssetsUploadPanel'
import { Asset, AssetFolder, AssetType } from 'graphql/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'store/hooks'
import { FileUpload } from 'utils/types'

export enum AssetsViewMode {
    ADD = 'ADD',
    UPLOAD = 'UPLOAD',
    PUBLISH = 'PUBLISH',
    SETTINGS = 'SETTINGS',
}

export default function AssetManagerPage() {
    const drawerGridProps: DrawerGridProps = {}

    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const websiteList = useStoreState((state) => state.model.websiteList)

    const assets = useStoreState((state) => state.model.assets)
    const folders = useStoreState((state) => state.model.folders)
    const tagList = useStoreState((state) => state.model.tagList)
    const createAssetFolder = useStoreActions((actions) => actions.model.createAssetFolder)
    const deleteAssetFolder = useStoreActions((actions) => actions.model.deleteAssetFolder)
    const updateAssetFolder = useStoreActions((actions) => actions.model.updateAssetFolder)
    const uploadAsset = useStoreActions((actions) => actions.model.uploadAsset)
    const deleteAsset = useStoreActions((actions) => actions.model.deleteAsset)
    const updateAsset = useStoreActions((actions) => actions.model.updateAsset)

    const [activeFolderID, setActiveFolderID] = useState<string | undefined | null>(undefined)
    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>()
    const [selectedFolder, setSelectedFolder] = useState<AssetFolder | undefined>()
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)

    const { t } = useTranslation()

    //internal state
    const [viewMode, setViewMode] = useState<AssetsViewMode | undefined>()

    //open the create folder panel
    const openCreateFolderPanel = () => {
        setViewMode(AssetsViewMode.ADD)
        setSelectedAsset(undefined)
        setSelectedFolder(undefined)
    }

    //open the create folder panel
    const openFileUploadPanel = () => {
        setViewMode(AssetsViewMode.UPLOAD)
        setSelectedAsset(undefined)
        setSelectedFolder(undefined)
    }

    // creates a new folder
    const onCreateFolder = (label: string) => {
        if (selectedWebsite) {
            createAssetFolder({
                name: label,
                websiteID: selectedWebsite.id,
                parentFolderID: activeFolderID,
            })
        }
        setViewMode(undefined)
    }

    // updates the meta data of a folder
    const onFolderUpdate = (label: string, tags: string[]) => {
        if (selectedFolder) {
            updateAssetFolder({
                id: selectedFolder.id,
                parentFolderID: selectedFolder.parentFolder,
                name: label,
                tags,
            })
        }
        setSelectedFolder(undefined)
    }

    // updates the meta data of a file
    const onFileUpdate = (label: string, tags: string[]) => {
        if (selectedAsset) {
            updateAsset({
                AssetID: selectedAsset.id,
                folderID: selectedAsset.folder,
                name: label,
                tags,
            })
        }
        setSelectedAsset(undefined)
    }

    // delete a folder by the given id
    const onFolderDelete = (folderID: string) => {
        if (confirm(t('pages.assetManagerPage.confirmDeleteFolder'))) {
            deleteAssetFolder(folderID)
        }
    }

    // delete a file by the given id
    const onFileDelete = (fileID: string) => {
        if (confirm(t('pages.assetManagerPage.confirmDeleteFile'))) {
            deleteAsset(fileID)
        }
    }

    // upload files
    const uploadFiles = (files: FileUpload[], shouldImagesResized: boolean, shouldImagesConvertedToWebp: boolean) => {
        for (const file of files) {
            let assetType = AssetType.OTHER
            if (file.type === 'application/pdf') {
                assetType = AssetType.PDF
            } else if (file.type.startsWith('image')) {
                assetType = AssetType.IMAGE
            }

            uploadAsset({
                file: file.base64Data.replaceAll('data:' + file.type + ';base64,', ''),
                folderID: activeFolderID ?? '',
                name: file.label,
                type: assetType,
                websiteID: selectedWebsite?.id ?? '',
                resizeImages: shouldImagesResized,
                convertToWebp: shouldImagesConvertedToWebp,
            })
        }
        setViewMode(undefined)
    }

    // set the current open folder
    const onSetActiveFolderID = (folderID: string | undefined | null) => {
        setActiveFolderID(folderID)
        setViewMode(undefined)
        setSelectedAsset(undefined)
        setSelectedFolder(undefined)
    }

    // close the drawer
    const onCancelDrawer = () => {
        setViewMode(undefined)
    }

    // asset details panel
    if (selectedAsset) {
        drawerGridProps['primary'] = (
            <AssetDetailsPanel
                tagList={tagList}
                asset={selectedAsset}
                onCancel={() => setSelectedAsset(undefined)}
                onSubmit={onFileUpdate}
            />
        )
        drawerGridProps['primaryWidth'] = DrawerWidth.LARGE
        drawerGridProps['collapsible'] = false
    }
    // folder panel
    else if (selectedFolder) {
        drawerGridProps['primary'] = (
            <AssetFolderPanel
                folder={selectedFolder}
                tagList={tagList}
                onCancel={() => setSelectedFolder(undefined)}
                onSubmit={onFolderUpdate}
            />
        )
        drawerGridProps['primaryWidth'] = DrawerWidth.MEDIUM
        drawerGridProps['collapsible'] = false
    }
    // create folder panel
    else if (viewMode === AssetsViewMode.ADD) {
        drawerGridProps['primary'] = (
            <AssetsFolderEditPanel tagList={tagList} onCancel={onCancelDrawer} onSubmit={onCreateFolder} />
        )
        drawerGridProps['primaryWidth'] = DrawerWidth.MEDIUM
        drawerGridProps['collapsible'] = false
    }
    // uplaod file panel
    else if (viewMode === AssetsViewMode.UPLOAD) {
        drawerGridProps['primary'] = <AssetsUploadPanel onCancel={onCancelDrawer} onSubmit={uploadFiles} />
        drawerGridProps['primaryWidth'] = DrawerWidth.MEDIUM
        drawerGridProps['collapsible'] = false
    }

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid
                {...drawerGridProps}
                content={
                    <>
                        <AssetGrid
                            allAssets={assets}
                            allFolders={folders}
                            activeFolderID={activeFolderID}
                            setActiveFolderID={onSetActiveFolderID}
                            openCreateFolderPanel={openCreateFolderPanel}
                            openFileUploadPanel={openFileUploadPanel}
                            onAssetActivated={(a: Asset) => setSelectedAsset(a)}
                            onFolderDetails={(f: AssetFolder) => setSelectedFolder(f)}
                            onFolderDelete={onFolderDelete}
                            onFileDelete={onFileDelete}
                            onAssetSelectToggled={(id: string) => console.log('onFileSelectToggled', id)}
                        />
                    </>
                }
            />
        </>
    )
}
